<template>
  <vx-card>
      <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen" v-model='tabIndex'>
        <!-- tabs -->
        <vs-tab icon-pack="feather" icon="icon-circle" :label="'General'"  >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <general />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-circle" :label="'Email'"  >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <email />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-circle" :label="'Job Order'"  >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <job-order />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-circle" :label="'Shipment'"  >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <shipment />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-circle" :label="'Invoice'"  >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <invoice />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-circle" :label="'GPS API'"  >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <gps />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-circle" :label="'Customer Channel'"  >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <customer-channel />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-circle" :label="'Vendor Channel'"  >
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <vendor-channel />
          </div>
        </vs-tab>
      </vs-tabs>

      <div class="btn-group ml-20">
      </div>
  </vx-card>
</template>

<script>
import General from './section/General.vue'
import Email from './section/Email.vue'
import JobOrder from './section/JobOrder.vue'
import Shipment from './section/Shipment.vue'
import Invoice from './section/Invoice.vue'
import gps from './section/GPS.vue'
import CustomerChannel from './section/CustomerChannel.vue'
import VendorChannel from './section/VendorChannel.vue'

export default {
  components:{
    General,
    Email,
    JobOrder,
    Shipment,
    gps,
    Invoice,
    CustomerChannel,
    VendorChannel
  },
  data() {
      return {
          tabIndex : 0,
      }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  }
}
</script>

<style lang="css" scoped>
</style>
