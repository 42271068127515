<template>
    <div class="w-full">
        <vs-row vs-w='12'>
            <div class='vx-col sm:w-full md:w-1/2'>
                <vs-row class='mb-2' vs-w='12'>
                        <vs-col vs-w='3'>
                            <b>API Key</b>
                        </vs-col>
                        <vs-col vs-w='9'>
                            <vs-input  class='w-full pr-10' type="text" v-model='formData.apikey' />
                        </vs-col>
                </vs-row>
            </div>

        </vs-row>

        <vs-row>
            <vs-col>
                <vs-button type='filled' class='mt-3' @click='submitForm()' color='primary'>Save</vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    components:{
      },
  data(){
    return {
      formData : {
          apikey : ''
      },
    };
  },
  methods: {
        ...mapActions({
            dispatchStore: 'application/storeGps',
            dispatchShow: 'application/show'
        }),
        async getSettingApplication() {
            let { data } = await this.dispatchShow('gps')
            // console.log(data)
            if(data.data) {
                this.formData = data.data
            }

        },
        async submitForm() {
            this.$vs.loading()
            try {
                let data = await this.dispatchStore(this.formData, 'gps')
                this.$emit('on-success', data)
                this.getSettingApplication()
                this.$vs.notify({
                    title: 'Oops!',
                    text: data.data.message,
                    color: 'success'
                  })
                this.$vs.loading.close()
            } catch (error) {
                this.$vs.loading.close()
                // console.log(error)
                if(error.data) {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: error.data.message,
                        color: 'danger'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: JSON.stringify(error),
                        color: 'danger'
                    })                    
                }
            }
        }
    },
  mounted() {
    this.getSettingApplication()
  },
    
};
</script>