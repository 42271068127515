<template>
    <div class="w-full">
        <vs-row vs-w='12'>
            <div class='vx-col sm:w-full md:w-1/2'>
                <vs-row class='mb-2' vs-w='12'>
                        <vs-col vs-w='3'>
                            <b>Mail Driver</b>
                        </vs-col>
                        <vs-col vs-w='9'>
                            <vs-input  class='w-full pr-10' type="text" v-model='formData.driver' />
                        </vs-col>
                </vs-row>

                <vs-row class='mb-2' vs-w='12'>
                    <vs-col vs-w='3'>
                        <b>Host</b>
                    </vs-col>
                    <vs-col vs-w='9'>
                        <vs-input  class='w-full pr-10' type="text" v-model='formData.host' />
                    </vs-col>
                </vs-row>

                <vs-row class='mb-2' vs-w='12'>
                    <vs-col vs-w='3'>
                        <b>Port</b>
                    </vs-col>
                    <vs-col vs-w='9'>
                        <vs-input  class='w-full pr-10' type="number" v-model='formData.port' />
                    </vs-col>
                </vs-row>
            </div>

        <div class='vx-col sm:w-full md:w-1/2'>
                
        <vs-row class='mb-2' vs-w='12'>
            <vs-col vs-w='3'>
                <b>Username</b>
            </vs-col>
            <vs-col vs-w='9'>
                <vs-input  class='w-full pr-10' type="text" v-model='formData.username' />
            </vs-col>
        </vs-row>

        <vs-row class='mb-2' vs-w='12'>
            <vs-col vs-w='3'>
                <b>Password</b>
            </vs-col>
            <vs-col vs-w='9'>
                <vs-input  class='w-full pr-10' type="password" v-model='formData.password' />
            </vs-col>
        </vs-row>

        <vs-row class='mb-2' vs-w='12'>
            <vs-col vs-w='3'>
                <b>Encryption</b>
            </vs-col>
            <vs-col vs-w='9'>
                <vs-radio class='mr-3' v-model='formData.encryption' vs-value='tls'>TLS</vs-radio>
                <vs-radio class='mr-1' v-model='formData.encryption' vs-value='ssl'>SSL</vs-radio>
            </vs-col>
        </vs-row>
            </div>
        </vs-row>

        <vs-row>
            <vs-col>
                <vs-button type='filled' class='mt-3' @click='submitForm()' color='primary'>Save</vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    components:{
      },
  data(){
    return {
      formData : {
          driver : '',
          host : '',
          port : '',
          username : '',
          password : '',
          encryption : '',
      },
    };
  },
  methods: {
        ...mapActions({
            dispatchStore: 'application/storeEmail',
            dispatchShow: 'application/show'
        }),
        async getSettingApplication() {
            let { data } = await this.dispatchShow('email')
            // console.log(data)
            if(data.data) {
                this.formData = data.data
            }

        },
        async submitForm() {
            this.$vs.loading()
            try {
                let data = await this.dispatchStore(this.formData, 'email')
                this.$emit('on-success', data)
                this.getSettingApplication()
                this.$vs.notify({
                    title: 'Oops!',
                    text: data.data.message,
                    color: 'success'
                  })
                this.$vs.loading.close()
            } catch (error) {
                this.$vs.loading.close()
                // console.log(error)
                if(error.data) {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: error.data.message,
                        color: 'danger'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: JSON.stringify(error),
                        color: 'danger'
                    })                    
                }
            }
        }
    },
  mounted() {
    this.getSettingApplication()
  },
    
};
</script>