<template>
    <div>
        <vs-row class='mb-2' vs-w='12'>
            <vs-col vs-w='2'>
            </vs-col>
            <vs-col vs-w='9'>
                <img ref="logoPreview" alt="Logo" class='normal-img'>
            </vs-col>
        </vs-row>

        <vs-row class='mb-2' vs-w='12'>
            <vs-col vs-w='2'>
                <b>Upload Logo</b>
            </vs-col>
            <vs-col vs-w='9'>
                <vs-input type="file" class='w-full' ref='logo' accept='image/*' @change='logoOnChange' />
            </vs-col>
        </vs-row>

    </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    components:{
      },
  data(){
    return {
      formData : {
          name : '',
          command : '',
          details : []
      },
    };
  },
  methods: {
        ...mapActions({
            dispatchStore: 'application/store',
            dispatchUpdate: 'application/update',
            dispatchShow: 'application/show'
        }),
        async getSettingApplication() {
            let { data } = await this.dispatchShow('general')
            // console.log(data)
            if(data.data) {
                if(data.data.logo) {
                    this.$refs.logoPreview.setAttribute('src', data.data.logo)
                }
            }

        },
        async logoOnChange(e) {
            const rawFile = e.target.files[0];
            if (!rawFile) return;
            let payload = new FormData()
            payload.append('logo', rawFile)
            this.$vs.loading()
            try {
                let data = await this.dispatchStore(payload)
                this.$emit('on-success', data)
                this.getSettingApplication()
                this.$vs.notify({
                    title: 'Oops!',
                    text: data.data.message,
                    color: 'success'
                  })
                this.$vs.loading.close()
                window.location.reload()
            } catch (error) {
                this.$vs.loading.close()
                // console.log(error)
                if(error.data) {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: error.data.message,
                        color: 'danger'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: JSON.stringify(error),
                        color: 'danger'
                    })                    
                }
            }
        }
    },
  mounted() {
    this.getSettingApplication()
  },
    
};
</script>