<template>
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="vx-row mb-5">
                <div class="vx-col w-full mb-1">
                    <b class="">Email Template</b>
                </div>
                <div class='vx-col w-full mb-1'>
                    <div @click="addVariable('$job_order_code')">
                        <vs-chip color="primary">
                            Job Order Code
                        </vs-chip>
                    </div>
                    <div @click="addVariable('$customer_name')">
                        <vs-chip color="primary">
                            Customer Name
                        </vs-chip>
                    </div>
                    <div @click="addVariable('$sender_name')">
                        <vs-chip color="primary">
                            Sender Name
                        </vs-chip>
                    </div>
                    <div @click="addVariable('$receiver_name')">
                        <vs-chip color="primary">
                            Receiver Name
                        </vs-chip>
                    </div>
                </div>
                <div class='vx-col w-full'>
                    <quill-editor :content="email_template" ref="myQuillEditor" @change="onEditorChange($event)"></quill-editor>
                </div>
            </div>
            <vs-divider></vs-divider>
            <div class="vx-row mb-5">
                <div class="vx-col w-full">
                    <div class='mb-2'>
                        <b>Barcode Type</b>
                    </div>
                    <div>
                        <vs-radio class='mr-5' v-model='barcode_type' vs-value='general'>General</vs-radio>
                        <vs-radio class='mr-1' v-model='barcode_type' vs-value='no_chassis'>Integrated with no chasis</vs-radio>
                    </div>
                </div>
            </div>

        </div>
        <div class="vx-col w-full flex">
            <vs-button type='filled' class='mt-3' @click="submitForm()" color='primary'>Save</vs-button>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
    components:{
        quillEditor
    },
    data(){
        return {
            email_template: '',
            barcode_type: ''
        };
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    methods: {
            ...mapActions({
                dispatchStore: 'application/storeEmailTemplateJobOrder',
                dispatchShow: 'application/show'
            }),
            async getSettingApplication() {
                let { data } = await this.dispatchShow('job_order')
                if(data.data) {
                    this.email_template = data.data.email_template
                    this.barcode_type = data.data.barcode_type
                }
            },
            onEditorChange({ html }) {
                this.email_template = html
            },    
            addVariable(variable) {
                let selection  = this.editor.selection.savedRange.index;
                this.editor.insertText(selection, variable);
            },
            async submitForm() {
                this.$vs.loading()
                try {
                    let param = {
                        email_template: this.email_template,
                        barcode_type: this.barcode_type
                    }
                    let data = await this.dispatchStore(param, 'job_order')
                    this.$emit('on-success', data)
                    this.getSettingApplication()
                    this.$vs.notify({
                        title: 'Oops!',
                        text: data.data.message,
                        color: 'success'
                    })
                    this.$vs.loading.close()
                } catch (error) {
                    this.$vs.loading.close()
                    // console.log(error)
                    if(error.data) {
                        this.$vs.notify({
                            title: 'Oops!',
                            text: error.data.message,
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            title: 'Oops!',
                            text: JSON.stringify(error),
                            color: 'danger'
                        })                    
                    }
                }
            }
        },
    mounted() {
        this.getSettingApplication()
    },
};
</script>