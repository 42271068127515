<template>
    <div class="w-full">
        <!-- <vs-row vs-w='12' class="mb-5">
            <div class='vx-col sm:w-full md:w-1/2'>
                <vs-row class='mb-2' vs-w='12'>
                    <vs-col vs-w='3'>
                        <b>Name</b>
                    </vs-col>
                    <vs-col vs-w='9'>
                        <vs-input  class='w-full pr-10' type="text" v-model='formData.name' />
                    </vs-col>
                </vs-row>
            </div>
        </vs-row> -->
        <b>List Menu</b>
        <vs-table multiple v-model="formData.menu_id" @selected="handleSelected" :data="menus" class="mb-3">
            <template slot="thead">
                <vs-th>Menu</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr.id" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.name"><pre v-html="tr.name"></pre></vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-row>
            <vs-col>
                <vs-button type='filled' class='mt-3' @click='submitForm()' color='primary'>Save</vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  data(){
    return {
      formData : {
          slug : 'customer',
          name : 'Customer',
          menu_id : []
      },
    };
  },
  computed:{
    ...mapState({
      menus: state => state.roles.all_menu
    })
  },
  methods: {
        ...mapActions({
            dispatchStore: 'application/storeChannel',
            dispatchShow: 'application/showChannel',
            dispatchShowAllMenu: 'roles/showAllMenu'
        }),
        async getSettingApplication() {
            let { data } = await this.dispatchShow(this.formData.slug)
            if(data.data) {
                if(data.data.channel != null) {
                    this.formData.name = data.data.channel.name
                }
                this.formData.menu_id = data.data.menu_id
            }
        },
        async getDataMenu(){
            await this.dispatchShowAllMenu()
        },
        async submitForm() {
            this.$vs.loading()
            try {
                let data = await this.dispatchStore(this.formData)
                this.$emit('on-success', data)
                this.getSettingApplication()
                this.$vs.notify({
                    title: 'Oops!',
                    text: data.data.message,
                    color: 'success'
                  })
                this.$vs.loading.close()
            } catch (error) {
                this.$vs.loading.close()
                // console.log(error)
                if(error.data) {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: error.data.message,
                        color: 'danger'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: JSON.stringify(error),
                        color: 'danger'
                    })                    
                }
            }
        },
        handleSelected(id) {
            if(this.formData.menu_id.includes(id)) {
                this.menus.forEach(obj => {
                if(obj.id == id) {
                    let path = obj.path.split(',');
                    let concatMenu = this.formData.menu_id.concat(path);
                    let setMenu = new Set(concatMenu);
                    let unionMenu = [];
                    for(let element of setMenu) {    
                        unionMenu.push(element); 
                    }
                    this.formData.menu_id = unionMenu;
                    this.addMenuChildRecursive(obj.id);
                    return;
                }
                });
            }
            else {
                this.menus.forEach(obj => {
                if(obj.id == id) {

                    let index = this.formData.menu_id.indexOf(id);
                    if (index > -1) {
                    this.formData.menu_id.splice(index, 1);
                    }

                    this.removeMenuChildRecursive(obj.id);
                    
                    let path = obj.path.split(',');
                    let checkFriend = false;
                    
                    this.menus.forEach(objB => {
                    if(objB.parent_id == obj.parent_id) {
                        if(this.formData.menu_id.includes(objB.id)) {
                        checkFriend = true;
                        return;
                        }
                    }
                    });

                    if(!checkFriend) {
                    for(let i = path.length - 1; i >= 0; i--) {
                        let index = this.formData.menu_id.indexOf(path[i]);
                        if (index > -1) {
                        this.formData.menu_id.splice(index, 1);
                        }
                        checkFriend = false;
                        this.menus.forEach(objC => {
                            if(objC.id == path[i]) {
                            this.menus.forEach(objD => {
                                if(objD.parent_id == objC.parent_id) {
                                if(this.formData.menu_id.includes(objD.id)) {
                                    checkFriend = true;
                                    return;
                                }
                                }
                            });
                            }
                        });
                        if(checkFriend) break;
                    }
                    }
                    return;
                }
                });
            }
            },
            addMenuChildRecursive(id) {
                this.menus.forEach(obj => {
                if(obj.parent_id == id) {
                    // let path = obj.path.split(',');
                    // let concatMenu = this.formData.menu_id.concat(path);
                    // let setMenu = new Set(concatMenu);
                    // let unionMenu = [];
                    // for(let element of setMenu) {    
                    //     unionMenu.push(element); 
                    // }
                    // this.formData.menu_id = unionMenu;

                    this.formData.menu_id.push(obj.id);
                    this.addMenuChildRecursive(obj.id);
                }
                });
            },
            removeMenuChildRecursive(id) {
                this.menus.forEach(obj => {
                if(obj.parent_id == id) {
                    let index = this.formData.menu_id.indexOf(obj.id);
                    if (index > -1) {
                    this.formData.menu_id.splice(index, 1);
                    }
                    this.removeMenuChildRecursive(obj.id);
                }
                });
            }
    },
  mounted() {
      this.getDataMenu()
    this.getSettingApplication()
  },
    
};
</script>