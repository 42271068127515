<template>
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="vx-row mb-5">
                <div class="vx-col w-full">
                    <b class="ml-2">Email Template</b>
                </div>
                <div class='vx-col w-full'>
                    <div @click="addVariable('$shipment_code')">
                        <vs-chip color="primary">
                            Shipment Code
                        </vs-chip>
                    </div>
                    <div @click="addVariable('$customer_name')">
                        <vs-chip color="primary">
                            Customer Name
                        </vs-chip>
                    </div>
                    <div @click="addVariable('$driver_name')">
                        <vs-chip color="primary">
                            Driver Name
                        </vs-chip>
                    </div>
                    <div @click="addVariable('$police_number')">
                        <vs-chip color="primary">
                            Police Number
                        </vs-chip>
                    </div>
                </div>
                <div class='vx-col w-full'>
                    <quill-editor :content="email_template" ref="myQuillEditor" @change="onEditorChange($event)"></quill-editor>
                </div>
            </div>
        </div>
        <div class="vx-col w-full flex">
            <vs-button type='filled' class='mt-3' @click="submitForm()" color='primary'>Save</vs-button>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
    components:{
        quillEditor
    },
    data(){
        return {
            email_template: ''
        };
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    methods: {
            ...mapActions({
                dispatchStore: 'application/storeEmailTemplateShipment',
                dispatchShow: 'application/show'
            }),
            async getSettingApplication() {
                let { data } = await this.dispatchShow('shipment')
                if(data.data) {
                    this.email_template = data.data.email_template
                    // let convertHtml = this.editor.clipboard.convert(data.data.email_template)
                    // this.editor.setContents(convertHtml, 'silent');
                }
            },
            onEditorChange({ html }) {
                this.email_template = html
            },    
            addVariable(variable) {
                let selection  = this.editor.selection.savedRange.index;
                this.editor.insertText(selection, variable);
            },
            async submitForm() {
                this.$vs.loading()
                try {
                    let param = {
                        email_template: this.email_template
                    }
                    let data = await this.dispatchStore(param, 'shipment')
                    this.$emit('on-success', data)
                    this.getSettingApplication()
                    this.$vs.notify({
                        title: 'Oops!',
                        text: data.data.message,
                        color: 'success'
                    })
                    this.$vs.loading.close()
                } catch (error) {
                    this.$vs.loading.close()
                    // console.log(error)
                    if(error.data) {
                        this.$vs.notify({
                            title: 'Oops!',
                            text: error.data.message,
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            title: 'Oops!',
                            text: JSON.stringify(error),
                            color: 'danger'
                        })                    
                    }
                }
            }
        },
    mounted() {
        this.getSettingApplication()
    },
};
</script>